.rdtPicker {
  @apply dark:bg-neutral-900 rounded-xl border-neutral-200 dark:border-neutral-700
}

.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
  @apply bg-neutral-200 rounded-lg dark:bg-neutral-700
}

.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
  @apply bg-primary-6000 dark:bg-primary-6000 rounded-lg;
}

.rdtPicker button:hover,
.rdtPicker thead tr:first-of-type th:hover  {
  @apply dark:bg-neutral-700 rounded-lg
}

td.rdtMonth:hover,
td.rdtYear:hover {
  @apply bg-neutral-200 dark:bg-neutral-700 rounded-lg
}