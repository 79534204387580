.spinner {
  border: 8px solid rgb(79, 70, 229);
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border-radius: 50%;
}

.spinner__dot {
  background-color: rgb(79, 70, 229);
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  margin: auto;
  -webkit-animation: circle 2s linear infinite;
  animation: circle 2s linear infinite;
};

@-webkit-keyframes circle {
  0% {
    transform: rotate(0deg) translate(-40px) rotate(0deg);
  }
  100% {
    transform: rotate(360deg)translate(-40px) rotate(-360deg);
  }
}

@keyframes circle {
  0% {
    transform: rotate(0deg) translate(-40px) rotate(0deg);
  }
  100% {
    transform: rotate(360deg)translate(-40px) rotate(-360deg);
  }
}